<template>
  <section class="page-section p-0 mt-3 pb-5">
  <div class="row">
    <div class="col-lg-12 m-auto">
      <div class="card">
        <h5
          class="card-header d-flex justify-content-between align-items-center"
        >
          <span>{{ $t("Files") }}</span>
          <!-- <div class="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="submit"
            >
              Save
            </button>
            <button type="button" class="btn btn-sm btn-light">Cancel</button>
          </div> -->
        </h5>
        <table class="card-body table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Category</th>
              <th scope="col">Category (HK)</th>
              <th scope="col">Category (CN)</th>
              <th scope="col">Name</th>
              <th scope="col">URL</th>
              <th scope="col">Access Control</th>
              <th scope="col">Created at</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in files" :key="file.id">
              <th scope="row">{{ file.id }}</th>
              <td>
                <span v-if="editView !== file.id">{{ file.category }}</span>
                <input
                  v-if="editView === file.id"
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="form.category"
                />
              </td>
              <td>
                <span v-if="editView !== file.id">{{ file.category_hk }}</span>
                <input
                  v-if="editView === file.id"
                  type="text"
                  class="form-control"
                  id="section-textpos-hk"
                  v-model="form.category_hk"
                />
              </td>
              <td>
                <span v-if="editView !== file.id">{{ file.category_cn }}</span>
                <input
                  v-if="editView === file.id"
                  type="text"
                  class="form-control"
                  id="section-textpos-cn"
                  v-model="form.category_cn"
                />
              </td>
              <td>
                <span v-if="editView !== file.id">{{ file.name }}</span>
                <input
                  v-if="editView === file.id"
                  type="text"
                  class="form-control"
                  v-model="form.name"
                />
              </td>
              <td>
                <a :href="file.url + '&token=' + getCookie('token')">{{ file.url }}</a>
              </td>
              <td>
                <span v-if="editView !== file.id">{{ file.role }}</span>
                <select
                  v-if="editView === file.id"
                  class="form-control"
                  v-model="form.role"
                >
                  <option value="public">Public</option>
                  <option value="investor">Investor</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <td>{{ file.created_at }}<br />{{ file.updated_at }}</td>
              <td>
                <button
                  v-if="editView === false"
                  type="button"
                  class="btn btn-sm btn-light"
                  @click="() => toggleEditView(file.id)"
                >
                  Edit
                </button>
                <button
                  v-if="editView === file.id"
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="submit"
                >
                  Save
                </button>
                <button
                  v-if="editView === file.id"
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="remove"
                >
                  Delete
                </button>
                <button
                  v-if="editView === file.id"
                  type="button"
                  class="btn btn-sm btn-light"
                  @click="cancel"
                >
                  Cancel
                </button>
              </td>
            </tr>
            <!-- new -->
            <tr>
              <th scope="row">New</th>
              <td>
              </td>
              <td>
              </td>
              <td>
              </td>
              <td>
                <!-- <input
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  disabled
                  v-model="newForm.name"
                /> -->
              </td>
              <td>
                <label for="file-upload" style="margin-bottom: 0">
                  <input
                    type="file"
                    id="file-upload"
                    style="display: none"
                    @change="handleFile"
                  />
                  <div class="btn-group" role="group">
                    <div
                      v-if="newFileText"
                      class="btn btn-sm btn-light btn-newFileText"
                    >
                      {{ newFileText }}
                    </div>
                    <div class="btn btn-sm btn-secondary">Upload</div>
                  </div>
                </label>
              </td>
              <td>
                <!-- <select
                  class="form-control"
                  id="section-html_only"
                  v-model="newForm.role"
                >
                  <option value="public">Public</option>
                  <option value="investor">Investor</option>
                  <option value="admin">Admin</option>
                </select> -->
              </td>
              <td></td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="newSubmit"
                >
                  Submit
                </button>
                <button type="button" class="btn btn-sm btn-light">
                  Clear
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </section>
</template>

<script>
import Form from "vform";
import axios from "axios";

export default {
  middleware: "auth",

  metaInfo() {
    return { title: this.$t("admin.file") };
  },

  data: () => ({
    editView: false,
    newFileText: null,
    files: [],
    status: "",
    form: new Form({
      category: "",
      category_hk: "",
      category_cn: "",
      name: "",
      role: "investor",
    }),
    newForm: new Form({
      category: "",
      name: "",
      role: "investor",
    }),
    newFile: null,
  }),

  mounted() {
    console.log("mounted");
    this.getFiles().then((records) => {
      this.files = records;
    });
    // this.form.email = this.$route.query.email;
    // this.form.token = this.$route.params.token;
  },

  methods: {
     getCookie: function(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    getFiles: async function (name) {
      const { data } = await axios.get(`${window.config.BASE_PATH}/api/files`);
      console.log(data);
      if (data && data.records) return data.records;
      else return [];
    },
    toggleEditView: function (id) {
      this.editView = id;
      if (id === false) return;
      let record = this.files.find((u) => u.id === id);
      console.log("file record selected", record);
      this.form.id = record.id;
      this.form.category = record.category;
      this.form.category_hk = record.category_hk;
      this.form.category_cn = record.category_cn;
      this.form.name = record.name;
      this.form.role = record.role;
    },
    submit: async function () {
      console.log("Save " + this.form.id, this.form);
      const { data } = await this.form.put(window.config.BASE_PATH + "/api/file");
      if (data.success) {
        this.cancel();
        this.getFiles().then((records) => {
          this.files = records;
        });
      }
    },
    cancel: function () {
      this.editView = false;
      this.form.reset();
    },
    remove: async function(){
      console.log("Save " + this.form.id, this.form);
      const { data } = await this.form.delete(window.config.BASE_PATH + "/api/file");
      if (data.success) {
        this.cancel();
        this.getFiles().then((records) => {
          this.files = records;
        });
      }
    },
    newSubmit: async function () {
      const file = this.newFile; //e.target.files[0];
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = (e) => {
      //   this.previewImage = e.target.result;
      // };

      var formData = new FormData();
      formData.append("file", file);

      axios.post(window.config.BASE_PATH + "/api/file",  formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          this.getFiles().then((records) => {
            this.files = records;
          });
        }
      });
    },
    clear: function () {
      this.newForm.reset();
    },
    handleFile: function (e) {
      const file = e.target.files[0];
      console.log(file);
      this.newFile = file
      // this.newFileText = "" + file.name;
      // if (file.name) this.newForm.name = file.name;
    },
  },
};
</script>
